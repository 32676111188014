
import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeFinancialForm from '../../components/game-forms/be-financial-form';
import BeGameInstructions from '../../components/be-game-instructions';
import useLocalStorageState from 'use-local-storage-state'

const FinancialPage = (props) => {
  const [experiment, setExperiment] = useLocalStorageState("experiment");
  const [dataUser, setDataUser] = React.useState();
  const handleOnChange = (event) => {
    dataUser[event.target.name] = event.target.value;
    setDataUser({...dataUser});
    console.log(dataUser);
  };

  React.useEffect(() => {
    setDataUser(props.location.state?.gamePosition !== undefined && experiment?.games[props.location.state.gamePosition].params || {
        conditionSelection: 'none',
        dataSelection: 'neutral'
      }
    );
  }, [])
	return(
        <GameCreationRoot title={"Financial decision making game"}>
            <BeGameInstructions gameId={'financialdecision'} dataUser={dataUser}  />
            <BeFinancialForm handleOnChange={handleOnChange} />
        </GameCreationRoot>
	);
}

export default FinancialPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "register"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;