import React from 'react';
import {
	Box,
	Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
	LinearProgress,
    Radio,
    RadioGroup
} from '@mui/material';
import { Formik, Form } from 'formik';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import useLocalStorageState from 'use-local-storage-state'
import { navigate } from "gatsby";
import { GamesContext, AuthContext } from '../../contexts/contexts.js';

const formContainer = {
	width: '100%',
	marginTop: '32px',
};

const FinancialGameForm = ({handleOnChange }) => {
	const { t } = useTranslation();
    const gamesInfo = React.useContext(GamesContext);
    const userInfo = React.useContext(AuthContext);
    const [experiment, setExperiment] = useLocalStorageState("experiment");
    const [gamePosition, setGamePosition] = useLocalStorageState("gamePosition", {defaultValue: undefined});

	const handleSubmit = async (values) => {
		try {
            if (gamePosition !== undefined) {
                const game = {...experiment.games[gamePosition], params: {...values}};

                experiment.games[gamePosition] = game;
            } else {
                const gameData = gamesInfo.filter((game) => game.id === "financialdecision");
                const game = {...gameData[0], params: {...values}};

                const gamesLikeThis = experiment.games.filter((g) => (game.id === g.id))
                const maxInstance = Math.max(gamesLikeThis.map((g) => g.instance))
                if(!maxInstance){
                    game.instance = 1;
                }
                else{
                    game.instance = maxInstance+1;
                }
                game["listId"] = `${game.id}_${game.instance}`;

                experiment.games.push(game);
            }
            
            setGamePosition(undefined);
            setExperiment(experiment);

		} catch(e) {
            console.log(e);
            //we should handle the error
		}
	}

	const validate = values => {
		const errors = {};
	 
		if (!values.conditionSelection) {
			errors.conditionSelection = t('Required');
        }

		if (!values.dataSelection) {
			errors.dataSelection = t('Required');
		}

		return errors;
	  };

    const goBack = () => {
        if(gamePosition !== undefined){
            navigate("/checkoutExperiment/");
        }
        else{
            navigate(-1);
        }
    }

	return(
        <Layout>
            <Formik
                initialValues={
                    (gamePosition !== undefined && experiment?.games[gamePosition].params) || {
                    conditionSelection: 'none',
                    dataSelection: 'neutral'
                }}
                style={formContainer}
                onSubmit={async (values, actions) => { 
                    await handleSubmit(values);
                    actions.setSubmitting = false;
                    goBack();
                }}
                validate={(values) => { return validate(values) }}
            >
                {({values, submitForm, resetForm, isSubmitting, setFieldValue}) => (
                    <Form onChange={handleOnChange}>
                        <Grid container spacing={2} width={'80%'}>
                            <Grid item xs={9}>
                                <FormControl component="fieldset">
                                    <FormLabel id="condition-group"><Trans>Select condition</Trans></FormLabel>
                                    <RadioGroup
                                        aria-labelledby="condition-group"
                                        name={"conditionSelection"}
                                        value={values.conditionSelection.toString()} onChange={(event) => {
                                            setFieldValue("conditionSelection", event.currentTarget.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            name={"conditionSelection"}
                                            control={<Radio />}
                                            label={t("None")}
                                            value={"none"}
                                        />
                                        <FormControlLabel
                                            name={"conditionSelection"}
                                            control={<Radio />}
                                            label={t("Unqualified opinion")}
                                            value={"unqualified"}
                                        />
                                        <FormControlLabel
                                            name={"conditionSelection"}
                                            control={<Radio />}
                                            label={t("Going concern qualified opinion")}
                                            value={"going_concern"}
                                        />
                                        <FormControlLabel
                                            name={"conditionSelection"}
                                            control={<Radio />}
                                            label={t("Qualified opinion for other reason but going concern")}
                                            value={"qualified_other_reason"}
                                        />
                                        <FormControlLabel
                                            name={"conditionSelection"}
                                            control={<Radio />}
                                            label={t("Unqualified opinion with an emphasis on matter paragraph")}
                                            value={"matter_paragraph"}
                                        />
                                        <FormControlLabel
                                            name={"conditionSelection"}
                                            control={<Radio />}
                                            label={t("Random")}
                                            value={"random"}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl component="fieldset">
                                    <FormLabel id="data-group"><Trans>Select data type</Trans></FormLabel>
                                    <RadioGroup
                                        aria-labelledby="data-group"
                                        name={"dataSelection"}
                                        value={values.dataSelection.toString()} onChange={(event) => {
                                            setFieldValue("dataSelection", event.currentTarget.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            name={"dataSelection"}
                                            control={<Radio />}
                                            label={t("Neutral")}
                                            value={"neutral"}
                                        />
                                        <FormControlLabel
                                            name={"dataSelection"}
                                            control={<Radio />}
                                            label={t("Negative")}
                                            value={"negative"}
                                        />
                                        <FormControlLabel
                                            name={"dataSelection"}
                                            control={<Radio />}
                                            label={t("Random")}
                                            value={"random"}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {isSubmitting && <LinearProgress />}
                        <Box margin={1}>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={isSubmitting || !userInfo?.userData.gamePermissions.financialdecision.usable}
                                onClick={() => {
                                    submitForm();
                                }}
                            >
                                {gamePosition !== undefined ? 
                                    <Trans>Modify game parameters</Trans>
                                :
                                    <Trans>Add game to experiment</Trans>
                                }
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    resetForm();
                                }}
                            >
                                <Trans>Reset values</Trans>
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="error"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    goBack();
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Layout>
	);
}

export default FinancialGameForm;